/* MyWork.css */

.my-work-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  text-align: center;
  background-attachment: fixed;
  animation: my-work-riseUp 1s ease-out; /* Apply the translateY animation */
}

/* Add keyframes for the grow animation */
@keyframes my-work-grow {
  from {
    transform: scaleX(0); /* Start with zero width */
    opacity: 0;
  }
  to {
    transform: scaleX(1); /* Grow to full width */
    opacity: 1;
  }
}

/* Add keyframes for the translateY animation */
@keyframes my-work-riseUp {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.my-work-title-container {
  position: relative;
}

.my-work-title {
  font-size: 36px;
  margin-top: 20px;
  padding: 10px 20px;
}

.my-work-title-container .my-work-header-line::after {
  content: ""; /* Add an empty content property */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px; 
  height: 4px;
  background-color: pink;
  border-radius: 1px;
}

.my-work-items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 30px;
  animation: my-work-grow 1s ease-out; /* Apply the grow animation */
  width: 90vw;
}

.work-item {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 4px solid pink;
  border-radius: 25px;
  background-color: rgb(252, 252, 252);
  animation: my-work-grow 1s ease-out; /* Apply the grow animation */
  overflow: hidden;
  width: 26vw;
  cursor: pointer;
}

.work-item img {
  width: 100%;
  margin-bottom: 10px;
}

.work-item h3 {
  font-weight: bold;
  font-size: 20px;
  color: black;
  margin: 0px 0 0px 0;
}

.work-item p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.my-updates-button {
  margin: 0 auto; 
  display: block; /* Change to block to take full width */
  width: fit-content; /* Adjust width based on content */
  padding: 15px 30px;
  font-size: 24px;
  text-decoration: none;
  color: black;
  background-color: pink;
  border-radius: 25px;
  transition: scale 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.my-updates-button:hover {
  background-color: #ff66b2; 
  scale: 1.05
}

.my-work-footer-line {
  opacity: 0;
}

/* Media Query for Portrait Mode */
@media screen and (orientation: portrait) {

  .my-work-items {
    margin: 50px auto; /* Added margin around the entire work items container */
    position: relative; /* Add position relative to the work items container */
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .work-item {
    margin: 20px auto;
    width: 80vw;
    display: flex;
    align-items: center;
  }

  .my-work-header-line {
    width: 100px;
  }

  .my-updates-button {
    margin-bottom: 20px;
    margin-top: -20px;
  }
}