/* About.css */

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: left;
  background-attachment: fixed;
}

.page-header,
.about-content,
.my-work-button {
  animation: riseUp 1s ease-out; 
}

@keyframes riseUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(15%);
    opacity: 0.25;
  }
  50% {
    transform: translateY(10%);
    opacity: 0.5;
  }
  75% {
    transform: translateY(5%);
    opacity: 0.75;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.page-header {
  position: relative;
}

.page-header h2 {
  font-size: 36px;
  padding: 10px 20px;
}

.page-header .header-line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background-color: pink;
  border-radius: 1px;
}

.about-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.about-image {
  flex: 1; 
  margin-right: -5vw;
  margin-top: -15vh;
  margin-left: -5vw;
  scale: .75;
}

.about-text {
  flex: 2; 
  max-width: 800px;
}

.about-text p {
  font-size: 22px;
  color: white;
}


.my-work-button {
  margin-top: 40px; 
  display: block;
  width: fit-content;
  padding: 15px 30px;
  font-size: 24px;
  text-decoration: none;
  color: black;
  background-color: pink;
  border-radius: 25px;
  transition: background-color 0.3s ease-in-out, scale 0.3s ease-in-out;
}

.my-work-button:hover {
  background-color: #ff66b2; /* Darker pink on hover */
  scale: 1.05;
}

.education-intro {
  text-align: center;
  font-size: 1.5rem;
  animation: riseUp 3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.degree {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  opacity: 0; /* Initial opacity set to 0 */
}

.animate {
  opacity: 1;
  transition: opacity 3s ease, transform 1s ease;
}

.degree-logo,
.degree-info {
  transition: opacity 3s ease, transform 1s ease;
}

.degree-logo {
  max-width: 150px;
  margin-right: 40px;
  transform: translateX(-100%); /* Initial position off-screen to the left */
  background-color: rgb(255,255,255,.9);
  border-radius: 10%;
}

.degree-info {
  flex: 1;
  transform: translateX(50%); /* Initial position off-screen to the right */
}

.animate .degree-logo,
.animate .degree-info {
  transform: translateX(0); /* Move in from the left/right when animated */
}

.degree:nth-child(2) .degree-logo,
.degree:nth-child(2) .degree-info {
  transition-delay: 0s; /* Delay for the second item */
}

.degree:nth-child(3) .degree-logo,
.degree:nth-child(3) .degree-info {
  transition-delay: .3s; /* Delay for the third item */
  margin-bottom: 10px;
}

.degree:nth-child(4) .degree-logo,
.degree:nth-child(4) .degree-info {
  transition-delay: .6s; /* Delay for the first item */
}

.school-name {
  font-size: 24px;
  margin-bottom: 5px;
}

.degree-name {
  font-size: 20px;
  margin-bottom: -20px;
}

.degree-description {
  font-size: 16px;
  margin-left: 0px;
}

.divider {
  height: 1px;
  color: pink;
  background-color: pink;
  border-radius: 20px;
  border: 1px solid pink;
  margin: 0 0 20px 0; /* Adjust margin as needed for your design */
  opacity: 1;
}

/* Mobile View */
@media screen and (orientation: portrait) {
  body {
    max-width: fit-content;
  }

  .about-content {
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    font-size: 25px;
    width: 100%;
  }

  .about-image {
    order: 0;
    margin-bottom: 1rem;
    animation: riseUp 1s ease-out;
    margin: 0 auto;
    scale: 0.4;
    margin: -35vh 0 -30vh 0;
    align-self: center;
    }

  .about-text {
    margin-top: 20px; 
    margin-bottom: 1.25rem;
  }

  .about-text p {
    width: 90vw;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  .my-work-button {
    margin-top: 10%; 
    font-size: 1.5rem;
    margin-right: 50%;
    width: 60vw;
    text-align: center;
  }

  .education-intro {
    margin-bottom: 40px;
  }

  .degree {
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
  }

  .degree-logo {
    max-width: 30vw;
    margin: 0 auto;
    order: 0;
  }

  .school-name {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;

  }
  
  .degree-name {
    font-size: 17px;
    margin-bottom: -20px;

  }
  
  .degree-description {
    font-size: 14px;
    margin-left: 0px;
  }
}
