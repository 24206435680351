/* SocialMediaIcons.css */

.social-icons {
  display: flex;
  align-items: center;
  gap: 1px;
}

.social-icons .social-link {
  text-decoration: none; 
}

.social-icons i {
  font-size: 25px;
  color: black;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons i:hover {
  color: rgb(128, 0, 255);
  transition: color 0.3s ease, transform 0.3s ease;
  transform: scale(1.3)
}

@media screen and (orientation: portrait) {
  .social-icons {
    display: none;
  }
}
