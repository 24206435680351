/* Publications.css */

.published-works-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.pub-divider {
  height: 1px;
  color: pink;
  background-color: pink;
  border-radius: 20px;
  border: 1px solid pink;
  margin: 0 0 50px 0; /* Adjust margin as needed for your design */
  opacity: 1;
}

.work-title {
  font-size: 36px;
  padding: 10px 20px;
}

.header-line {
  position: relative;
}

.works-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.works-list {
  list-style: none;
  padding: 0;
}

.publication {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
}

.publication h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.publication p {
  font-size: 16px;
  margin: 5px 0;
}

.publication a {
  display: block;
  color: lightblue;
  text-decoration: underline;
  font-weight: bold;
  margin-left: 44vw;
  margin-right: 44vw;
  margin-top: 15px;
}

@keyframes riseUp {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.work-title-container .subtitle {
  font-size: 18px;
  color: white;
  margin-top: 30px;
  animation: riseUp 1s ease-out;
  margin-bottom: 40px;
}

.blue-link {
  color: lightblue; /* Blue color */
  text-decoration: underline;
}

.blue-link:visited {
  color: lightpink; /* Change the color for visited links */
}

.blank {
  opacity: 0;
}

@media screen and (orientation: portrait) {
  .published-works-container {
    margin: 20px auto;
  }

  .publication a {
    margin: 0 auto;
    font-size: larger;
    margin-top: 20px;
  }

  p.subtitle {
    font-size: 20px;
  }

  .subtitle a {
    font-size: 20px;
  }
  
}