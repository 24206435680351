/* ToolsandModels.css */

.tools-models-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  text-align: center;
  background-attachment: fixed;
  animation: tools-riseUp 1s ease-out;
}

.divider {
  display: none;
}

.tools-models-work-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 30px;
  animation: tools-grow 1s ease-out;
}

.tools-models-work-item {
  margin-bottom: 20px;
  width: calc(30% - 20px); /* Adjust width based on the number of items in a row */
  border: 2px solid pink;
  border-radius: 10px;
  background-color: lightgray;
}

.tools-models-work-item:hover {
  cursor: pointer;
}

.tools-models-work-item img {
  border: 1px solid black;
  border-radius: 1px;
  width: 100%;
}

.tools-models-work-item h3 {
  font-weight: bold;
  font-size: 20px;
  margin: 1vh auto;
  color: black;
}

.tools-models-work-item p {
  font-size: 18px;
  color: black;
  margin-bottom: 10px;

}

.tools-models-title {
  font-size: 36px;
  margin-bottom: 10px; 
  padding: 10px 20px;
}

.tools-models-header-line {
  content: "";
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  background-color: pink;
  border-radius: 1px;
  width: 100px;
}

.tools-models-section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 3%;
  text-align: center;
}

.tools-models-section-subtitle {
  font-size: 18px;
  color: white;
  animation: tools-riseUp 1s ease-out;
  width: 80%;
  margin: auto;
}

.tools-models-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
}

.tools-models-expanded-image {
  max-width: 100%;
  max-height: 100vh;
  cursor: pointer;
}

.tools-models-updates-button {
  margin-top: 40px; 
  display: block;
  width: fit-content;
  padding: 15px 30px;
  font-size: 24px;
  text-decoration: none;
  color: black;
  background-color: pink;
  border-radius: 25px;
  transition: background-color 0.3s ease-in-out, scale 0.3s ease-in-out;
}

.tools-models-updates-button:hover {
  background-color: #ff66b2; /* Darker pink on hover */
  scale: 1.05;
}

/* Media Query for Portrait Mode */
@media screen and (orientation: portrait) {
  .tools-models-work-items {
    margin: 20px auto;
    position: relative;
    flex-direction: column;
  }
  
  .tools-models-work-item {
    margin-bottom: 20px;
    width: 100%; /* Take full width in portrait mode */
  }

  .tools-models-section-title {
    margin-top: 5%;
  }

  .tools-models-section-subtitle {
    width: 100%;
  }

  .divider {
    display: flex;
  }

  .tools-models-header-line {
    margin-bottom: 5vh;
  }

  .tools-models-title {
    font-size: 2rem;
    }
}

@keyframes tools-riseUp {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes tools-grow {
  from {
    transform: scaleX(0);
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    opacity: 1;
  }
}