/* Contact.css */
body, html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.contact-page {
  text-align: center;
  animation: riseUp 1s ease-out; /* Apply the translateY animation */
}

@keyframes riseUp {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.contact-header {
  margin-bottom: 20px;
  margin-top: 50px;
}

.contact-header h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.header-line {
  width: 100px;
  height: 4px;
  background-color: pink;
  margin: 0 auto;
  border-radius: 1px;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  height: 60vh;
  background-attachment: fixed;
}

.contact-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 80px;
  margin-top: 50px;
}

.contact-section {
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 230px;
  padding: 10px;
  text-decoration: none;
  background-color: rgba(253, 110, 213, 0.01);
  /* border: 1px solid pink; */
  border-radius: 10px;
}

.contact-section:hover .icon-wrapper {
  background-color: #ff66b2;
  border-radius: 30%;
  border: 1px solid white;
}

.contact-section:hover .icon {
  color: white;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: lightpink;
  border: 1px solid purple;
  margin-right: 10px;
  transition: border-radius 0.3s ease-in-out, background-color 0.2s ease-in-out;
}

.icon-wrapper:hover {
  background-color: #ff66b2;
  border-radius: 30%;
}

.icon {
  font-size: 24px;
  color: black;
}

.contact-section p {
  margin: 10px;
  font-size: 20px;
  color: white;
  text-shadow: 1px 0px 1px black;
  font-weight: bold;
}

.contact-form {
  width: 64%;
  margin-right: 50px;
}

.contact-form h2 {
  font-size: 24px;
  font-weight: bold; 
  margin: 30px auto;
  text-align: center;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  font-size: 18px;
  margin-bottom: 5px;
}

/* Adjustments for the name and email fields */
.contact-form .name-email-container {
  display: flex;
  gap: 10px;
}

.contact-form input[type="text"],
.contact-form input[type="email"] {
  margin-bottom: 25px;
  flex: 1;
  padding: 8px;
  font-size: 14px; /* Adjust the font size for name and email fields */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.contact-form textarea {
  margin-bottom: 10px;
  padding: 8px;
  font-size: 16px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.contact-form button {
  width: 200px;  /* Adjusted width for a smaller button */
  padding: 8px 15px; /* Adjusted padding for a smaller button */
  font-size: 16px;  /* Adjusted font size */
  background-color: pink;  /* Changed background color to pink */
  border: none;
  border-radius: 10px;  /* Added border-radius for rounded corners */
  cursor: pointer;
  color: black;
  margin: 10px auto;
  transition: background-color 0.3s ease-in-out, scale 0.3s ease-in-out;
}

.contact-form button:hover {
  background-color: #ff66b2;
  scale: 1.05;
}

.google-maps-container {
  width: 90vw;
  height: 60vh; 
  border: 2px solid purple;
  margin: 20px auto;
  color: black;
}

.find-me {
  font-size: 24px;
  font-weight: bold;
  margin: 0px auto;
  text-align: center;
  margin-top: -60px;
}

@media screen and (orientation: portrait) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: auto;
  }

  .contact-links {
    align-items: left;
    margin-top: 0px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .contact-form {
    width: 95%;
    margin: 20px auto;
    margin-bottom: 30px;
  }

  .contact-form h2 {
    font-size: 24px;
    margin: 20px auto;
    text-align: center;
  }

  .contact-form form {
    display: flex;
    flex-direction: column;
  }

  .contact-form .name-email-container {
    flex-direction: column;
    gap: 0px;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type="subject"] {
    margin-bottom: 15px;
    font-size: 16px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .contact-form textarea {
    margin-bottom: 10px;
    font-size: 16px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .contact-form button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: pink;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    color: black;
    margin: 10px auto;
  }

  .find-me {
    margin-top: 0px;
  }
}
