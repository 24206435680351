body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Inter', Arial, sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 10vh;
  background-color: white;
  color: black;
}

.logo {
  scale: 0.2;
  margin-left: -400px;
  margin-right: -500px;
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.nav-links {
  display: flex;
  gap: 30px; /* Increase the gap between navbar links */
  align-items: center; /* Center the items vertically */
  /* margin-right: 120px; */
}

.nav-links a {
  color: black;
  text-decoration: none;
  font-size: 1.25rem;
  position: relative;
  transition: color 0.3s ease-in-out; /* Add color transition */
}

.nav-links a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px; /* Adjust the distance from the text */
  width: 0;
  height: 2px;
  background-color: purple;
  transition: width 0.3s ease-in-out; /* Add width transition */
}

.nav-links a:hover {
  color: black;
}

.nav-links a:hover::after,
.nav-links a.active::after {
  width: 50%; /* Adjust the width based on the text length */
}

.nav-links a.active-link {
  color: black;
}

.nav-links a.active-link::after {
  width: 100%; /* Adjust the width based on the text length */
}


@media screen and (orientation: portrait) {
  .menu-icon {
    display: block;
    font-size: 1.8rem;
    transition: transform 0.3s ease-in-out;
  }

  .menu-icon.open {
    transform: rotate(90deg); /* Rotate the icon when the menu is open */
  }
  
  .menu-icon.close {
    transform: rotate(0deg); /* Start and end position for the animation */
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 10vh;
    left: 0;
    background-color: white;
    padding: 20px 0;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .nav-links.active {
    max-height: fit-content; /* Adjust this value based on your content */
    opacity: 1;
    z-index: 1;
  }

  .logo {
    scale: 0.15;
    margin-left: -470px;
  }

  .nav-links a {
    position: relative;
    padding-bottom: 10px; /* Add padding to create space for the dividing line */
  }

  .nav-links a.active-link {
    color: purple !important; /* This isn't working... 😭😭😭😭😭😭😭😭😭😭😭😭 */
    background-color: pink !important; /* Also not working 🤬😡👿😤😠*/
  }

  .nav-links a::after {
    content: '';
    bottom: 0; /* Adjust the distance from the text to place the line below the text */
    width: 100%; /* Stretch the line across the full width */
    height: 3px; /* Set the height of the dividing line */
    background-color: pink; /* Set the color of the dividing line */
    transition: background-color 0.3s ease-in-out, height 0.3s ease-in-out;
  }

  .nav-links a:hover::after,
  .nav-links a.active::after,
  .nav-links a.active-link::after {
    background-color: purple; 
    width: 100%;
    height: 4px;
  }
}



