/* RecentUpdates.css */
body,
html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.recent-updates-container {
  display: flex;
  flex-direction: row; /* Change to row to have elements side by side */
  justify-content: space-between; /* Spread elements across the container */
  align-items: flex-start;
  padding: 20px;
  text-align: left;
  background-attachment: fixed;
  margin-top: 50px;
}

.contact-header {
  text-align: center;
  margin-bottom: 50px;
}

.contact-header h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.header-line {
  width: 5%;
  height: 4px;
  background-color: purple;
  margin: 0 auto;
  border-radius: 1px;
}

.recent-updates-info {
  width: 100%; /* Adjusted width to take full width in mobile view */
  margin-bottom: 20px; /* Add margin at the bottom to separate from the updates */
}

.recent-updates-info h2 {
  font-size: 40px;
  text-align: center;
}

.update-container {
  width: 90%; /* Adjusted width to take 90% of the viewport width in mobile view */
  margin: 0 auto; /* Center the container horizontally */
}

.recent-updates-info img {
  width: 100%; /* Make the image take full width */
  max-width: 400px; /* Limit the max width of the image as needed */
  margin: 0 auto; /* Center the image */
}

.update {
  max-width: 100%; /* Adjusted max width of updates */
  padding: 20px;
  border: 1px solid pink;
  border-radius: 5px;
}

.update h3 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.update p {
  font-size: 16px;
  color: #555;
}

.contact-button {
  margin-top: auto; /* Remove the margin-top property */
  display: block; /* Change to block to take full width */
  width: fit-content; /* Adjust width based on content */
  margin: 0 auto; /* Center the button horizontally */
  padding: 15px 30px;
  font-size: 24px;
  text-decoration: none;
  color: black;
  background-color: pink;
  border-radius: 25px;
  transition: background-color 0.3s ease-in-out;
}

.contact-button:hover {
  background-color: #ff66b2; /* Darker pink on hover */
}

@keyframes riseUp {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.recent-updates-container,
.contact-header,
.header-line,
.recent-updates-info,
.update-container,
.contact-button {
  opacity: 0; /* Set initial opacity to 0 */
  animation: riseUp 1s ease-out forwards; /* Apply the translateY animation with forwards to keep the final state */
}

.recent-updates-container {
  animation-delay: 0.05s; /* Apply a delay to the container */
}

.contact-header {
  animation-delay: 0.4s; /* Apply a delay to the header */
}

.header-line {
  animation-delay: 0.6s; /* Apply a delay to the header line */
}

.recent-updates-info {
  animation-delay: 0.8s; /* Apply a delay to the info section */
}

.update-container {
  animation-delay: 1s; /* Apply a delay to the update container */
}

.contact-button {
  animation-delay: 1.5s; /* Apply a delay to the contact button */
}

.contact-button:hover {
  background-color: #ff66b2;
}

/* Media Query for Mobile View */
@media screen and (orientation: portrait) {
  .recent-updates-container {
    flex-direction: column; /* Change to column to stack elements in mobile view */
    align-items: center;
  }

  .recent-updates-info,
  .update-container {
    width: 100%; /* Take full width in mobile view */
  }

  .recent-updates-info img {
    max-width: 40%; /* Take full width in mobile view */
  }
}
