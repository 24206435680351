/* Home.css */
body, html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #8e44ad;
  background: linear-gradient(to right, #8e44ad, #341f97);
  color: white;
}

.home-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Added to create space between the two divs */
  text-align: center;
  background-attachment: fixed;
  overflow: -moz-hidden-unscrollable;
}

.header {
  flex: 1;
  text-align: left;
  max-width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the items horizontally */
  animation: headerScale 1s ease-out; /* New animation for the header */
}

.header h1 {
  font-weight: bold;
  font-size: 72px;
  text-align: center;
  color: white;
}

.header h2 {
  font-size: 50px;
  text-align: center;
  color: white;
}

.header p {
  font-size: 40px;
  color: white;
  text-align: center;
  margin-top: 5%;
}

.about-button {
  margin-top: 8%; /* Added margin to separate the button from the text */
  display: inline-block;
  padding: 15px 30px;
  font-size: 24px;
  text-decoration: none;
  color: black;
  background-color: pink;
  border-radius: 25px;
  transition: scale 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.about-button:hover {
  background-color: #ff66b2; /* Darker pink on hover */
  scale: 1.05;
}

.home-image {
  scale: 0.95;
}

@media screen and (max-width: 768px) {
  body {
    background: linear-gradient(to bottom, #8e44ad, #341f97);
  }

  .home-container {
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
  }

  .home-image {
    order: 1;
    animation: riseUp 1s ease-out;
    margin-top: -26vh;
    scale: 0.5;
  }

  .header h1 {
    font-size: 2.2rem; 
  }

  .header h2 {
    font-size: 1.8rem;
  }

  .header p {
    font-size: 1.5rem; 
  }

  .about-button {
    order: 2;
    margin-top: 1.5rem; 
    font-size: 1.6rem;
  }
}

/* New keyframes for header scaling animation */
@keyframes headerScale {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* RiseUp animation for the image */
@keyframes riseUp {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
