/* TalksandWorkshops.css */

@import '~@fortawesome/fontawesome-free/css/all.css';

body {
  width: 100vw;
}

@keyframes moveUp {
  from {
    transform: translateY(8%); 
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.talks-workshops-container {
  padding: 20px;
  text-align: center;
  animation: moveUp 1s ease-out; 
}

.talks-workshops-section {
  margin-bottom: 20px;
  margin-top: 20px;
  /* border: 2px solid pink;
  border-radius: 5px; */
  /* background-color: rgb(255, 255, 255, 0.3); */
}

h2 {
  margin-top: 20px;
}

p {
  margin-top: 30px;
}

.talks-workshops-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto; 
}

.talks-workshops-images-row2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto; 
}

.teaching-image {
  scale: 0.2;  
  display: flex;
  justify-content: center;
  margin: -400px 0 -400px 0;
  background-color: white;
  border: 1px solid black;
}

.image-row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.talks-workshops-images img {
  width: 30vw;
  height: 30vh;
  object-fit: contain; 
  margin-right: 10px;
  margin-bottom: 10px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid black;
}

.talks-workshops-images-row2 img {
  width: 30vw;
  height: 30vh;
  object-fit: contain; 
  margin-right: 10px;
  margin-bottom: 10px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid black;
}

.talks-workshops-final-image img {
  width: 30vw;
  height: 30vh;
  object-fit: contain; 
  margin-bottom: 20px;
}

.talks-workshops-section h2 {
  font: 400 1.5em/1.5em "Roboto", sans-serif;
  margin-bottom: 5px;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 25px;
  color: #333; /* Adjust the color as needed */
}

.icon.left {
  left: 5px;
}

.icon.right {
  right: 5px;;
}

.contact-button {
  transition: background-color 0.3s ease-in-out, scale 0.3s ease-in-out;
}

.contact-button:hover {
  background-color: #ff66b2;
  scale: 1.05;
}

.header-line {
  margin-bottom: 50px;
}

.tnw-divider {
  height: 1px;
  color: pink;
  background-color: pink;
  border-radius: 20px;
  border: 1px solid pink;
  margin: 50px 0 50px 0; /* Adjust margin as needed for your design */
  opacity: 1;
}

/* Mobile View */
@media (max-width: 768px) {
  body {
    max-width: 100vw;
    height: auto;
  }

  .talks-workshops-images img {
    width: 80vw;
    object-fit: contain; 
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .talks-workshops-images-row2 img {
    width: 80vw;
    object-fit: contain; 
    margin-right: 10px;
    margin-bottom: 30px;
    margin-top: -20px;
  }

  .teaching-image {
    scale: 0.06;  
    display: flex;
    justify-content: center;
    margin: -500px 10vw -500px 10vw;
  }

  .talks-workshops-final-image {
    scale: 2;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
  }
}



